import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';
/****Layouts*****/

const ProtectedLayout = Loadable(lazy(() => import('../layouts/ProtectedLayout')));
const GlobalLayout = Loadable(lazy(() => import('../layouts/GlobalLayout')));
/***** Pages ****/
/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../pages/Error')));
const LoginForm = Loadable(lazy(() => import('../pages/auth/loginForm')));
const SignupForm = Loadable(lazy(() => import('../pages/auth/signupForm')));
const CompleteInfo = Loadable(lazy(() => import('../pages/auth/completeInfo')));
const ForgetForm = Loadable(lazy(() => import('../pages/auth/forgetForm')));

/*****Routes******/
const Dashboard = Loadable(lazy(() => import('../pages/common/dashboard')));
const AddEvent = Loadable(lazy(() => import('../pages/common/event/add')));
const QuickEvent = Loadable(lazy(() => import('../pages/common/event/quick')));
const Events = Loadable(lazy(() => import('../pages/common/event/index')));
const EditEvent = Loadable(lazy(() => import('../pages/common/event/edit')));

const Sans = Loadable(lazy(() => import('../pages/common/sans/index')));
const AddSans = Loadable(lazy(() => import('../pages/common/sans/add')));
const EditSans = Loadable(lazy(() => import('../pages/common/sans/edit')));
const ReportSans = Loadable(lazy(() => import('../pages/common/sans/report')));

const QrCode = Loadable(lazy(() => import('../pages/common/checker/qrcode')));
const BarCode = Loadable(lazy(() => import('../pages/common/checker/barcode')));


const ReportOrdersSummery = Loadable(lazy(() => import('../pages/common/report/orderSummery')));
const ReportFinancial = Loadable(lazy(() => import('../pages/common/report/financial')));

const Supports = Loadable(lazy(() => import('../pages/common/support/index')));
const NewSupport = Loadable(lazy(() => import('../pages/common/support/add')));
const Support = Loadable(lazy(() => import('../pages/common/support/show')));

const Profile = Loadable(lazy(() => import('../pages/common/profile')));

const Factors = Loadable(lazy(() => import('../pages/common/factors/index')));
const FactorsShow = Loadable(lazy(() => import('../pages/common/factors/show')));



const ThemeRoutes = [
  {
    path: '/',
    element: <ProtectedLayout />,
    children: [
      { path: '/', name: 'داشبورد', exact: true, element: <Dashboard /> },

      { path: '/events', name: 'برنامه‌ها', exact: true, element: <Events /> },
      { path: '/addEvent', name: 'افزودن برنامه', exact: true, element: <AddEvent /> },
      { path: '/quickEvent', name: 'افزودن سریع', exact: true, element: <QuickEvent /> },
      { path: '/editEvent/:id', name: 'ویرایش برنامه', exact: true, element: <EditEvent /> },

      
      { path: '/sans', name: 'سانس‌ها', exact: true, element: <Sans /> },
      { path: '/addSans', name: 'افزودن سانس', exact: true, element: <AddSans /> },
      { path: '/editSans/:id', name: 'ویرایش سانس', exact: true, element: <EditSans /> },
      { path: '/reportSans/:id', name: 'وضغیت سانس', exact: true, element: <ReportSans /> },

      { path: '/qrcode', name: 'چک بلیت', exact: true, element: <QrCode /> },
      { path: '/barcode', name: 'چک بلیت', exact: true, element: <BarCode /> },

      { path: '/profile', name: 'حساب کاربری', exact: true, element: <Profile /> },

      { path: '/order', name: 'سفارشات', exact: true, element: <ReportOrdersSummery /> },
      { path: '/financial', name: 'مالی', exact: true, element: <ReportFinancial /> },

      { path: '/supports', name: 'پشتیبانی', exact: true, element: <Supports /> },
      { path: '/newSupport', name: 'افزودن درخواست', exact: true, element: <NewSupport /> },
      { path: '/support/:id', name: 'نمایش درخواست', exact: true, element: <Support /> },

      { path: '/factor', name: 'فاکتور', exact: true, element: <Factors /> },
      { path: '/factor/:id', name: 'نمایش فاکتور', exact: true, element: <FactorsShow /> },
      

      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/auth',
    element: <GlobalLayout />,
    children: [
      { path: '', element: <Navigate to="/auth/login" /> },
      { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
      { path: 'login', element: <LoginForm /> },
      { path: 'signup', element: <SignupForm /> },
      { path: 'complete-info', element: <CompleteInfo /> },
      { path: 'forget', element: <ForgetForm />}
    ],
  },
];

export default ThemeRoutes;
