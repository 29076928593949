import axios from "axios";
import Cookies from 'js-cookie';

axios.interceptors.response.use(res=>res,async (error)=>{
    if (error?.response?.status > 400 && error?.response?.status!==481 ){
        Cookies.remove("access")
        Cookies.remove("role")
        Cookies.remove("token")
        Cookies.remove("refreshToken")
        window.location.href = "/"
    }
    return Promise.reject(error);
});
