import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { createContext, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({children}) => {
  const [token, setToken] = useState(Cookies.get("token") || null);
  const [level, setLevel] = useState(Cookies.get("level") || 0);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = (data) => {
    setToken(data.accessToken);
    setLevel(data.info.level);
    Cookies.set("level",data.info.level)
    Cookies.set("token",data.accessToken)
    Cookies.set("refreshToken",data.refreshToken);
    navigate("/dashboard");
  };

  // call this function to sign out logged in user
  const logout = () => {
    setToken(null);
    setLevel(0);
    Cookies.remove("level")
    Cookies.remove("token")
    Cookies.remove("refreshToken")
    navigate("/", { replace: true });
  };

  const updateLevel = (data)=>{
    setLevel(data);
    Cookies.set("level",data)
  }

  const value = useMemo(
    () => ({
      token,
      login,
      logout,
      updateLevel,
      level
    }),
    [token,level]
  );
  return <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>;
};

AuthProvider.propTypes = {
    children: PropTypes.node,
};

export const useAuth = () => {
  return useContext(AuthContext);
};