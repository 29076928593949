import { configureStore } from '@reduxjs/toolkit';
import _ from 'lodash';
import UserReducer from './user/userSlice';
import CustomizerReducer from './customizer/CustomizerSlice';

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch {
        // ignore write errors
    }
};

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};


const persistedState = loadState();

const store= configureStore({
    preloadedState: persistedState,
    reducer: {
      customizer: CustomizerReducer,
      userReducer: UserReducer,
    }
})

store.subscribe(_.throttle(() => {
    saveState(store.getState());
}, 100));

export default store;