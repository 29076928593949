import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  customizerSidebar: false,
  isMobileSidebar: false,
};

export const CustomizerSlice = createSlice({
  name: 'customizer',
  initialState,
  reducers: {
    ToggleCustomizer: (state) => {
      state.customizerSidebar = !state.customizerSidebar;
    },
    ToggleMobileSidebar: (state) => {
      state.isMobileSidebar = !state.isMobileSidebar;
    },
  },
});

export const {
  ToggleCustomizer,
  ToggleMobileSidebar,
  FixedSidebar,
} = CustomizerSlice.actions;

export default CustomizerSlice.reducer;
